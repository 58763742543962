import unescape from '@/core/helpers/unescape';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

const MAX_RETRIES = 3;

export const ROUTE_ROOT = 'root_url';
export const ROUTE_ACCOUNT = 'account_url';
export const ROUTE_ACCOUNT_LOGIN = 'account_login_url';
export const ROUTE_ACCOUNT_LOGOUT = 'account_logout_url';
export const ROUTE_ACCOUNT_REGISTER = 'account_register_url';
export const ROUTE_ACCOUNT_ADDRESSES = 'account_addresses_url';
export const ROUTE_COLLECTIONS = 'collections_url';
export const ROUTE_ALL_PRODUCTS = 'all_products_collection_url';
export const ROUTE_SEARCH = 'search_url';
export const ROUTE_CART = 'cart_url';
export const ROUTE_CART_ADD = 'cart_add_url';
export const ROUTE_CART_CHANGE = 'cart_change_url';
export const ROUTE_CART_CLEAR = 'cart_clear_url';
export const ROUTE_PRODUCT_RECOMMENDATIONS = 'product_recommendations_url';
export const CHECKOUT = 'checkout';

// eslint-disable-next-line import/no-mutable-exports
export let loaded = false;

// eslint-disable-next-line import/no-mutable-exports
export let objects = {};

export function set(values = {}) {
    objects = values;
}

export const retryCalls = async (callback = async () => null, ...params) => {
    let retryCount = 0;

    let exception = null;

    while (retryCount < MAX_RETRIES) {
        try {
            return await callback(...params);
        } catch (error) {
            retryCount++;

            exception = error;

            await new Promise((resolve) => {
                setTimeout(resolve, 1000 * retryCount);
            });
        }
    }

    throw exception;
};

export async function loadObjects() {
    try {
        const { default: axios } = await import("axios");
        if (!axios) {
            throw new Error("Unable to load axios");
        }

        const client = axios.create({
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        if (!client) {
            return window?.globals;
        }

        const response = await client.get('/', {
            responseType: 'json',
            params: {
                view: 'objects',
            }
        })?.then((response) => {
            return response?.data;
        });

        if (!response) {
            try {
                Bugsnag.notify(new Error(`[${PREFIX}] Get global objects error`), (event) => {
                    event.severity = 'error';

                    event.addMetadata('parsedError', {
                        url: '/',
                        method: "GET",
                        params: {
                            view: 'objects',
                        },
                        response,
                    });
                });
            } catch (error) {}

            throw new Error("Empty response");
        }

        const keys = Object?.keys(response);
        if (!keys) {
            try {
                Bugsnag.notify(new Error(`[${PREFIX}] Get global objects error`), (event) => {
                    event.severity = 'error';

                    event.addMetadata('parsedError', {
                        url: '/',
                        method: "GET",
                        params: {
                            view: 'objects',
                        },
                        response,
                    });
                });
            } catch (error) {}

            throw new Error("Empty response");
        }

        if (!keys?.length) {
            try {
                Bugsnag.notify(new Error(`[${PREFIX}] Get global objects error`), (event) => {
                    event.severity = 'error';

                    event.addMetadata('parsedError', {
                        url: '/',
                        method: "GET",
                        params: {
                            view: 'objects',
                        },
                        response,
                    });
                });
            } catch (error) {}

            throw new Error("Empty response");
        }

        return response;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to load global objects`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    url: '/',
                    method: "GET",
                    params: {
                        view: 'objects',
                    },
                    response: error?.data || error?.response,
                    error,
                });
            });
        } catch (error) {}


        throw error;
    }
}

export async function load() {
    if (!!loaded) {
        return window?.globals;
    }

    const response = await retryCalls(loadObjects);

    loaded = true;
    objects = response;

    document.dispatchEvent(new CustomEvent('theme:objects:loaded', {
        detail: {
            objects
        }
    }));

    window.globals = window.globals || {};
    window.globals = {
        ...window.globals,
        ...(objects || {})
    };

    return window?.globals;
}

export function routes(routeName) {
    const routes = window?.globals?.routes;
    if (!routes) {
        return null;
    }

    if (!Object?.keys(routes)?.length) {
        return null;
    }

    const route = routes[routeName];
    if (!route) {
        return null;
    }


    return !!route?.endsWith('/') ? route : `${route}/`;
}

export function settings() {
    return window?.globals?.settings || null;
}

export function get(name) {
    try {
        const value = name?.split('.')?.reduce((objects, key) => {
            if (!!objects?.hasOwnProperty(key)) {
                return objects[key];
            }

            return null;
        }, window.globals || {});

        return unescape(value);
    } catch (error) {
        try {
            Bugsnag.notify(error);
        } catch (error) {}
    }

    return null;
}

export function all() {
    return window.globals || {};
}

export function customer() {
    return window.globals?.customer || null;
}

export function shop() {
    return window.globals?.shop || null;
}

export default {
    loaded: false,
    objects: window.globals,
    load,
    routes,
    get,
    all,
    customer,
    shop,
    settings
};
